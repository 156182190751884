import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/barngrindonline/gatsby/node_modules/gatsby-theme-base/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../components/image.js";
import ProsAndCons from "../components/prosAndCons.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "vikbara-barngrindar"
    }}>{`Vikbara barngrindar`}</h1>
    <p>{`Vikbara barngrindar är inte så vanligt och kan mest liknas vid rullbara barngrindar. Till skillnad mot rullbara barngrindar så viker man ihop grinden istället för att rulla ihop den. Detta medför att den inte behöver vara av tyg vilket kan göra den mer robust. Den "svaga" punkten (eller snarare punkterna) i en vikbar grind är i själva skarven/vecken. Men tack vara att men spänner upp den och fäster i ändarna så påverkar detta inte funktionaliteten och grinden kan anses vara säker.`}</p>
    <h2 {...{
      "id": "för-och-nackdelar"
    }}>{`För och nackdelar`}</h2>
    <ProsAndCons pros_list={["Enkel att montera", "Tar minimal när den är öppen", "Mer robust än motsvarande rullbar barngrind"]} cons_list={["Kan vara svåra att öppna med en hand", "Kräver att man borrar i väggen"]} pros_header={"Fördelar"} cons_header={"Nackdelar"} mdxType="ProsAndCons" />
    <h2 {...{
      "id": "hur-fungerar-en-vikbar-grind"
    }}>{`Hur fungerar en vikbar grind?`}</h2>
    <p>{`Vid montering skruvar man upp en stolpe på varje sida av öppningen. På ena sidan är själva grinden fäst, som består av sektioner som man kan vika ihop, jämför med en vikbar vägg. På andra sidan finns fästen för att fästa grinden när den är stängd. För att öppna grunden hakar man helt enkelt loss den i ena änden och viker ihop den.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      